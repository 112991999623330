<!--通用的翻页栏-->
<template>
  <div class="pagination_bar">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-size="pagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
      />
  </div>
</template>

<script>
  export default {
    name: 'Scg-pagination',
    data() {
      return{
        currentPageNum: this.pagination.currentPage
      }
    },
    props: {
      pagination: {
        currentPage: {
          type: Number,
          default: 1
        },
        pageSize: {
          type: Number,
          default: 10
        },
        total: {
          type: Number,
          default: 0
        }
      }
    },
    methods: {
      handleSizeChange(val) {
        console.log(`每页${val}条`)
        this.$emit("handleSizeChange", val)
        
      },
      handleCurrentChange(val) {
        this.currentPageNum = val
        this.$emit("currentPageChange", val)
      },
    }
  }
</script>

<style lang="stylus" scoped>
  .pagination_bar
    background-color #ffffff
    display flex
    justify-content flex-end
    align-items center
    padding-bottom 20px
    padding-left 30px
    margin-top 28px

    .pagination_button
      border-color #FFFFFF
      width 60px
      height 28px
      background linear-gradient(180deg,rgba(97,221,239,1) 0%,rgba(50,184,219,1) 98%)
      border-radius 3px
</style>
<style lang="stylus">

</style>