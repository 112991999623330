<template>
  <div class="scg-form-btn-container">
    <div class="btn">
      <el-button type="primary" @click="handleAdd" v-if="showAdd">{{ addTxt }}</el-button>
      <slot></slot>
    </div>
    <div class="btn">
      <el-button type="primary" @click="handleSearch" v-if="showFormBasicBtn">查询</el-button>
      <el-button type="normal" @click="handleReset" v-if="showFormBasicBtn">重置</el-button>
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "Scg-form-btn",
  props: {
    addTxt:{
      type: String,
      default: "新增"
    },
    showAdd:{
      type: Boolean,
      default: false
    },
    showFormBasicBtn:{
      type: Boolean,
      default: true
    },
  },
  data(){
    return {}
  },
  methods:{
    handleAdd(){
      this.$emit('add')
    },
    handleSearch(){
      this.$emit('search')
    },
    handleReset(){
      this.$emit('reset')
    }
  }
}
</script>
<style lang="stylus" scoped>
.scg-form-btn-container
  display flex
  justify-content space-between
  margin-bottom 10px
</style>