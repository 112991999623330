import { render, staticRenderFns } from "./Scg-form-btn.vue?vue&type=template&id=d1143fb8&scoped=true&"
import script from "./Scg-form-btn.vue?vue&type=script&lang=js&"
export * from "./Scg-form-btn.vue?vue&type=script&lang=js&"
import style0 from "./Scg-form-btn.vue?vue&type=style&index=0&id=d1143fb8&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1143fb8",
  null
  
)

export default component.exports